:root {
  --primary: #00599c;
  --sidebarbg: #003c69;
  --authBg: #f4faff;
  --white: #ffffff;
  --orange: #ff6636;
}

/* ALL SIDEBAR CLASSES TO CHANGE style*/
.sidebar aside.ant-layout-sider.ant-layout-sider-dark {
  background: var(--sidebarbg) !important;
}
.sidebar .ant-menu {
  background-color: var(--sidebarbg);
  color: var(--textgraycolor);
  padding-bottom: 50px;
}
/* .sidebar .ant-layout {
  width: 100% !important;
} */
.sidebar .ant-menu-submenu-title {
  color: #000 !important;
}

.sidebar .ant-menu .ant-menu-item-selected {
  background-color: var(--white) !important;
  color: var(--sidebarbg) !important;
  margin-left: 10px;
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.sidebar .ant-layout-header {
  padding-inline: 0;
}
.sidebar .ant-menu-item:hover {
  background-color: var(
    --sidebarbg
  ) !important; /* when hovering over on an item */
}
.sidebar .ant-menu-item-selected:hover {
  background-color: var(--white) !important; /* when hovering over on an item */
}
.sidebar li.ant-menu-item:active {
  background-color: var(--bs-white) !important;
}
.sidebar .ant-menu .ant-menu-item-selected:active {
  background-color: var(--sidebarbg) !important;
}

.sidebar .ant-menu .ant-menu-submenu-selected .ant-menu-submenu-title {
  color: var(--sidebarbg) !important;
}
.sidebar .ant-menu-submenu .ant-menu-inline {
  background: var(--bs-white) !important;
  border-radius: 10px !important;
  /* margin:0px 15px 0px 15px !important; */
}
.ant-menu-submenu-popup .ant-menu .ant-menu-item {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  background-color: var(--bs-white);
  color: #000;
}
.ant-menu-submenu-popup
  .ant-menu
  .ant-menu-submenu
  .ant-menu-submenu-title
  .ant-menu-title-content
  p,
.ant-menu-submenu-arrow {
  display: flex;
  justify-content: flex-start !important;
  align-items: center !important;
  background-color: var(--bs-white);
  color: #000 !important;
}
.ant-layout-sider-collapsed {
  width: 50px !important;
  min-width: 50px !important;
}
.ant-menu-submenu-popup .ant-menu .ant-menu-item-selected {
  background-color: var(--sidebarbg);
}
.ant-menu .ant-menu-sub {
  background-color: var(--bs-white) !important;
}
/* .ant-menu .ant-menu-sub li{
  padding-left: 21px !important;
} */
/* .ant-menu-submenu-popup .ant-menu .ant-menu-item:hover{
  background-color: var(--sidebarbg) !important;
}
.Nested_report .ant-menu-submenu-title{
  padding-left: 0px !important;
}
.Nested_report  .ant-menu .ant-menu-sub .ant-menu-inline{
  margin: 0px !important;
} */
/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c8ced4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--sidebarbg);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(
    --orange
  ) !important; /* Change the background color to green when checked */
  border: none !important;
  border-radius: 0px;
}
.ant-select-selector {
  border-radius: 0px !important;
}
.ant-checkbox-inner::after {
  border-color: white; /* Change the tick mark color to white */
}
.citySlider .slick-list .slick-track {
  /* min-width: 3000px !important; */
  /* max-width: 4000px !important; */
  /* @apply flex justify-center items-center */
  /* width: 280px !important; */
}
.citySlider .slick-list .slick-slide {
  padding: 0 10px;
  text-align: center;
  /* @apply flex justify-center items-center */
  /* width: 280px !important; */
}
.ant-collapse-content-box {
  padding: 0px !important;
}
.nestedCollaps {
  border-radius: 0px !important;
  background-color: white !important;
  border: none;
}
/* contact modal css */
.contactmodal .ant-modal-content {
  padding: 0px !important;
}
.slick-arrow {
  display: none !important;
}

.ant-switch-checked {
  background-color: var(--primary) !important;
}
.ant-form-item-label {
  padding: 0px !important;
}

/* antd multiselect css */
.interests .ant-select-selector {
  padding: 10px !important;
  border-radius: 5px !important;
  background-color: rgb(252, 252, 252) !important;
}
.interests
  .ant-select-selector
  .ant-select-selection-overflow
  .ant-select-selection-overflow-item {
  margin: 2px !important;
  background-color: var(--primary) !important;
  @apply font-poppins text-white font-semibold text-xs rounded-md;
}
.interests
  .ant-select-selector
  .ant-select-selection-overflow
  .ant-select-selection-overflow-item-suffix {
  background-color: transparent !important;
  color: black !important;
}
.interests
  .ant-select-selector
  .ant-select-selection-overflow
  .ant-select-selection-overflow-item
  .ant-select-selection-item
  .ant-select-selection-item-remove {
  @apply font-poppins text-white rounded-md font-semibold;
}

/* ANTD PROGRESSS TEXT */
.ant-progress .ant-progress-bg{
border-radius: 0px !important;
}
.ant-progress-text-inner{
  color: white !important;
  @apply text-xs text-white font-poppins;
}